(function () {
    angular.module('informaApp')
        .service('BasicChartTemplate', ['d3', 'ChartElementsHelper', BasicChartTemplateService]);

    function BasicChartTemplateService(d3, ChartElementsHelper) {
        var defaultConfig = {
            margin: {
                left: 100,
                top: 10,
                bottom: 200,

                xAxis: {
                    left: 10
                }
            },

            height: 500,

            xAxis: {
                labels: ['X values', 'here:']
            },

            yAxis: {
                labelFormatter: null
            },

            grid: false,

            chartWidthPercent: 1
        };

        return function (container, config) {
            config = _.merge({}, defaultConfig, config);

            var self = this;

            this.svg = createSvg(d3.select(container), config);

            var svgWidth = this.svg.node().getBoundingClientRect().width;
            config.chartWidth = svgWidth * config.chartWidthPercent;

            this.getConfig = function () {
                return _.merge({}, config);
            };

            this.xAxisLabel = drawXAxisLabel(this.svg, config);

            this.chartContainer = createChartContainer(this.svg, this.xAxisLabel.attr('x'), config);

            this.yAxis = {
                container: createYAxisContainer(this.chartContainer)
            };

            var labelXSize = self.xAxisLabel.node().getBoundingClientRect();

            const containerMargin = 30;
            var xAxisWidth = config.chartWidth - config.margin.left - config.margin.xAxis.left - labelXSize.width - containerMargin;
            var xAxisLabelHeight = labelXSize.height;
            var xAxisTop = config.height - config.margin.bottom - labelXSize.height;

            this.bottomContainer = createBottomContainer(this.chartContainer, xAxisWidth, xAxisTop, config);
            this.xAxisContainer = createXAxisContainer(this.bottomContainer);

            this.updateYAxis = function (y0, y1, duration) {
                var result = updateYAxis(d3, self.yAxis.container, labelXSize.height, config, [y0, y1], duration);

                self.yAxis = _.merge({}, self.yAxis, result);

                addStylesForYAxis(self.yAxis.container);

                var top = config.height - config.margin.bottom - labelXSize.height / 2 + 4;
                self.bottomContainer.attr('transform', 'translate(' + config.margin.xAxis.left + ', ' + top + ')');

                if (config.grid) {
                    drawGrid(self.chartContainer, self.yAxis.scale, config, xAxisWidth);
                }
            };

            this.updateXAxis = function (xAxisValues, tickFormat, names) {
                self.xAxis = createXAxis(d3, ChartElementsHelper, this.bottomContainer, this.xAxisContainer, xAxisWidth, xAxisLabelHeight, xAxisValues, tickFormat, names);
            };

            this.getY = function (value) {
                return self.yAxis.scale(value);
            };

            this.getX = function (value) {
                return self.xAxis.scale(value) + self.xAxis.scale.rangeBand() / 2 + config.margin.xAxis.left;
            };

            this.destroy = function () {
                self.svg.remove();
            }

            this.setXAxisVisibility = (isVisible) => {
                this.svg.selectAll('.item-label-bold').style('display', isVisible ? 'block' : 'none');
            }
        }
    }

    function drawGrid(chartContainer, yScale, config, width) {
        var ticks = yScale.ticks();

        chartContainer.selectAll('line.grid-line')
            .data(ticks)
            .enter()
            .append('line')
            .attr('class', 'grid-line')
            .attr('x1', config.margin.xAxis.left)
            .attr('x2', config.margin.xAxis.left + width)
            .attr('y1', function(x) { return yScale(x); })
            .attr('y2', function(x) { return yScale(x); })
            .attr('stroke', 'rgba(0, 0, 0, 0.1)')
            .attr('stroke-width', 1);
    }

    function addStylesForYAxis(container) {
        container.selectAll('line, path')
            .attr('fill', 'none')
            .attr('stroke', '#000')
            .attr('shape-rendering', 'crispEdges');
    }

    function createSvg(container, config) {
        return container
            .append('svg')
            .attr('height', config.height)
            .attr('width', '100%');
    }

    function drawXAxisLabel(svg, config) {
        var xAxisLabel = svg.append('text')
            .classed('caption', true)
            .attr('y', config.height - config.margin.bottom + config.margin.top)
            .attr('text-anchor', 'end')
            .text(config.xAxis.labels[0]);

        xAxisLabel.attr('x', function() {
            var width = this.getBoundingClientRect().width;
            return config.margin.left + width;
        });

        for (var i = 1; i < config.xAxis.labels.length; i++) {
            var item = xAxisLabel.append('tspan')
                .attr('x', xAxisLabel.attr('x'))
                .attr('dy', 14)
                .text(config.xAxis.labels[i]);
        }

        return xAxisLabel;
    }

    function createChartContainer(svg, x, config) {
        return svg.append('g')
            .attr('transform', 'translate(' + x + ', ' + config.margin.top + ')')
            .attr('width', 150);
    }

    function createYAxisContainer(chartContainer) {
        return chartContainer.append('g').attr('class', 'y axis');
    }

    function updateYAxis(d3, yAxisContainer, labelHeight, config, scale, duration) {
        var yScale = d3.scale.linear()
            .domain(scale)
            .range([config.height - config.margin.bottom - labelHeight, 0]);

        var yAxis = d3.svg.axis()
            .scale(yScale)
            .orient('left')
            .tickFormat(config.yAxis.labelFormatter)

        yAxisContainer.transition()
            .duration(duration)
            .call(yAxis);

        return {
            scale: yScale,
            axis: yAxis
        };
    }

    function createBottomContainer(chartContainer, xAxisWidth, xAxisTop, config) {
        return chartContainer.append('g')
            .attr('transform', 'translate(' + config.margin.xAxis.left + ', ' + xAxisTop + ')')
            .attr('width', xAxisWidth);
    }

    function createXAxisContainer(bottomContainer) {
        return bottomContainer.append('g')
            .attr('transform', 'translate(0, 0)')
            .attr('class', 'x axis')
            .style('font-size', '12px');
    }

    function createXAxis(d3, ChartElementsHelper, bottomContainer, xAxisContainer, xAxisWidth, xAxisLabelHeight, xAxisValues, tickFormat, names) {
        var xScale = d3.scale.ordinal()
            .rangeRoundBands([0, xAxisWidth])
            .domain(xAxisValues);

        var xAxis = d3.svg.axis()
            .scale(xScale)
            .orient('bottom')
            .tickFormat(tickFormat);

        xAxisContainer.call(xAxis);

        xAxisContainer.selectAll('line, path')
            .style('display', 'none');

        var topLine = createDivider(bottomContainer, 0, xAxisWidth, 0);
        var bottomLine = createDivider(bottomContainer, 0, xAxisWidth, xAxisLabelHeight);

        bottomContainer
            .selectAll('text.item-label')
            .data(names)
            .enter()
            .append('text')
            .attr('class', 'item-label-bold')
            .text(function(x) { return x })
            .attr('text-anchor', 'end')
            .attr('transform', function (d) {
                return 'rotate(-90)'
            })
            .attr('dx', -35)
            .attr('dy', 1)
            .attr('y', function(x, i) { return xScale(xAxisValues[i]) + xScale.rangeBand() / 2 + 5; })
            .attr('x', -bottomContainer.node().getBoundingClientRect().height)
            .call(ChartElementsHelper.wrapWord, 130);

        return {
            scale: xScale,
            axis: xAxis
        }
    }

    function createDivider(bottomContainer, x1, x2, y) {
        return bottomContainer.append('line')
            .classed('divider', true)
            .attr('x1', x1)
            .attr('y1', y)
            .attr('x2', x2)
            .attr('y2', y)
            .attr('stroke', '#5a5f6b')
            .attr('stroke-width', 1)
    }
})();